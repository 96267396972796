import { graphql, useStaticQuery } from 'gatsby';

export const useNutritionistConsultationText = () => {
  const { directusNutritionistConsultation } = useStaticQuery(graphql`
    query NutritionistConsultationText {
      directusNutritionistConsultation {
        page_title
        page_description
        hero_image {
          filename_disk
        }
        hero_header
        hero_text
        hero_price_text
        hero_old_price
        hero_new_price
        hero_cta
        health_goals_header
        health_goals_text
        health_goals_items {
          icon
          text
        }
        whats_included_image {
          filename_disk
        }
        whats_included_header
        whats_included_items {
          text
        }
        hiw_title
        hiw_items {
          title
          description
          filename_disk
        }
        experts_header
        experts_text
        experts {
          filename_disk: image
          name
          role
          qualification
        }
        gifts_header
        gifts_icon
        gifts_text
        faq_header
        faq_description
        faqs {
          question
          answer
        }
      }
    }
  `);
  return directusNutritionistConsultation;
};
