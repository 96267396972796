import React from 'react';

import Layout from '@components/layout';
import SEO from '@components/seo';
import HowItWorks from '@components/how-it-works';
import { useNutritionistConsultationText } from '@hooks/directusHooks/nutritionistConsultationText';
import StillHaveQuestion from '@components/still-have-question/still-have-question';
import ExpertApproval from '@components/expert-approval';
import AddToBasket from '@components/add-to-basket';
import Image from '@components/image/image';

import styles from './nutritionist-consultation.module.scss';

interface IHealthGoal {
  icon: string;
  text: string;
}

interface IWhatsIncluded {
  text: string;
}

const NutritionistConsultation = () => {
  const text = useNutritionistConsultationText();

  return (
    <Layout>
      <SEO title={text.page_title} description={text.page_description} />
      <div className={`${styles.section} ${styles.heroSection}`}>
        <div className={styles.left}>
          <Image filename={text.hero_image.filename_disk} width={1000} hideOnTablet />
        </div>
        <div className={`${styles.right} ${styles.hero}`}>
          <div className={styles.wrap}>
            <h1 className={styles.heroTitle}>{text.hero_header}</h1>
            <hr />
            <Image
              filename={text.hero_image.filename_disk}
              width={900}
              hideOnDesktop
              className={styles.onSmallScreen}
            />
            <p className={styles.subHeader}>{text.hero_text}</p>
            <div className={styles.price}>
              <p>
                {text.hero_price_text} <span className={styles.scretch}>{text.hero_old_price}</span>{' '}
                {text.hero_new_price}
              </p>
            </div>
            <div className={styles.buttonPrimaryWrap}>
              <AddToBasket
                isBundle={true}
                showButtonOnly={true}
                sku="nutritionist-consultation-one-time"
              />
            </div>
          </div>
        </div>
      </div>
      <section className={styles.section}>
        <div className={styles.sectionWrap}>
          <div className={styles.left}>
            <h2>{text.health_goals_header}</h2>
            <hr />
            <p dangerouslySetInnerHTML={{ __html: text.health_goals_text }} />
          </div>
          <div className={styles.right}>
            <div className={styles.healtGoalsItems}>
              {text.health_goals_items.map((item: IHealthGoal) => (
                <div className={styles.item} key={item.icon}>
                  <img src={`${process.env.GATSBY_MEDIA_URL}${item.icon}`} alt="" />
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className={`${styles.section} ${styles.yellowSection}`}>
        <div className={styles.sectionWrap}>
          <div className={styles.left}>
            <picture className={styles.heroImage}>
              <Image filename={text.whats_included_image.filename_disk} width={1440} />
            </picture>
          </div>
          <div className={`${styles.right} ${styles.whatsIncluded}`}>
            <h2>{text.whats_included_header}</h2>
            <div>
              {text.whats_included_items.map((item: IWhatsIncluded) => (
                <div className={styles.item} key={item.text}>
                  <img src="/images/icon-check-green.svg" alt="" />
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
            <div className={styles.price}>
              <p>
                {text.hero_price_text} <span className={styles.scretch}>{text.hero_old_price}</span>{' '}
                {text.hero_new_price}
              </p>
            </div>
            <AddToBasket
              isBundle={true}
              showButtonOnly={true}
              sku="nutritionist-consultation-one-time"
            />
          </div>
        </div>
      </section>
      <HowItWorks title={text.hiw_title} items={text.hiw_items} />
      <ExpertApproval
        title="Our Experts"
        description="Meet our highly experienced and fully qualified in-house nutritionists, Roxane and Christina."
        experts={text.experts}
        backgroundColor="PrimaryLighter"
      />
      <section className={`${styles.section} ${styles.giftsSection}`}>
        <div className={styles.sectionWrap}>
          <div className={`${styles.left} ${styles.icon}`}>
            <picture className={styles.icon}>
              <source
                srcSet={`${process.env.GATSBY_MEDIA_URL}${text.gifts_icon}?w=700`}
                media="(max-width: 420px)"
              />
              <source
                srcSet={`${process.env.GATSBY_MEDIA_URL}${text.gifts_icon}?w=1440`}
                media="(min-width: 421px)"
              />
              <img src={`${process.env.GATSBY_MEDIA_URL}${text.gifts_icon}`}></img>
            </picture>
          </div>
          <div className={`${styles.right} ${styles.giftWrap}`}>
            <h1 className={styles.heroTitle}>{text.gifts_header}</h1>
            <hr />
            <p dangerouslySetInnerHTML={{ __html: text.gifts_text }} />
          </div>
        </div>
      </section>
      <StillHaveQuestion accordion={text.faqs} description={text.faq_description} />
    </Layout>
  );
};

export default NutritionistConsultation;
